import { z } from 'zod';

export const CountryApiSchema = z.object({
  id: z.number(),
  display_name: z.nullable(z.string()).optional(),
  iso_2: z.nullable(z.string()).optional(),
  iso_3: z.nullable(z.string()).optional(),
  num_code: z.nullable(z.number()).optional(),
  region_id: z.nullable(z.string()).optional(),
  name: z.nullable(z.string()).optional(),
});

export const RegionApiSchema = z.object({
  id: z.string(),
  automatic_taxes: z.nullable(z.boolean()).optional(),
  currency_code: z.nullable(z.string()).optional(),
  gift_card_taxable: z.nullable(z.boolean()).optional(),
  name: z.nullable(z.string()).optional(),
  tax_code: z.nullable(z.string()).optional(),
  tax_provider_id: z.nullable(z.string()).optional(),
  tax_rate: z.nullable(z.number()).optional(),
  countries: z.nullable(z.array(CountryApiSchema)).optional(),
  fulfillment_providers: z
    .nullable(
      z.array(
        z.object({
          id: z.nullable(z.string()).optional(),
          is_installed: z.nullable(z.boolean()).optional(),
        }),
      ),
    )
    .optional(),
  payment_providers: z
    .nullable(
      z.array(
        z.object({
          id: z.nullable(z.string()).optional(),
          is_installed: z.nullable(z.boolean()).optional(),
        }),
      ),
    )
    .optional(),
});
export type RegionFromApi = z.infer<typeof RegionApiSchema>;
export type CountryFromApi = z.infer<typeof CountryApiSchema>;

export type Country = {
  id: number;
  displayName: string;
  iso_2: string;
  iso_3?: string;
  numCode?: number;
  regionId?: string;
  name?: string;
};

export type Region = {
  id: string;
  automaticTaxes?: boolean;
  currencyCode?: string;
  giftCardTaxable?: boolean;
  name?: string;
  taxCode?: string;
  taxProviderId?: string;
  taxRate?: number;
  countries?: Country[];
  fulfillmentProviders?: {
    id?: string;
    isInstalled?: boolean;
  }[];
  paymentProviders?: string[] | null;
};
