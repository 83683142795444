import { useTenant } from '../context/tenant-context';

const REVALIDATE_WINDOW = process.env.REVALIDATE_WINDOW || 60 * 30; // 30 minutes

export const useCatalogRequest = () => {
  const { backendUrl } = useTenant();

  const catalogRequest = async (
    method: string,
    path = '',
    payload?: {
      query?: Record<string, any>;
      body?: Record<string, any>;
    },
  ) => {
    const options: RequestInit = {
      credentials: 'include',
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      next: {
        revalidate: parseInt(REVALIDATE_WINDOW.toString()),
        tags: ['medusa_request'],
      },
    };

    if (payload?.body) {
      options.body = JSON.stringify(payload.body);
    }

    if (payload?.query) {
      const params = objectToURLSearchParams(payload.query!).toString();
      path = `${path}?${params}`;
    }

    let langParam = '';
    const storedLng = localStorage?.getItem('i18nextLng')?.split('-')[0];
    if (storedLng) {
      const langParamOperator = path.includes('?') ? '&' : '?';
      langParam = `${langParamOperator}lang=${storedLng}`;
    }

    const limit = payload?.query?.limit || 100;
    const offset = payload?.query?.offset || 0;
    const nextPage = offset + limit;

    const result = await fetch(
      `${backendUrl}/admin${path}${langParam}`,
      options,
    );
    const body = await result.json();

    if (body.errors) {
      console.error('Failed to fetch data', body.errors);
      throw body.errors[0];
    }

    if (!result.ok) {
      throw {
        status: result.status,
        message: body.message,
      };
    }

    body.nextPage = body.count > nextPage ? nextPage : null;
    return {
      status: result.status,
      body,
    };
  };

  return { catalogRequest };
};

function objectToURLSearchParams(obj: Record<string, any>): URLSearchParams {
  const params = new URLSearchParams();

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((value: any) => {
        params.append(`${key}[]`, value);
      });
    } else {
      params.append(key, obj[key]);
    }
  }

  return params;
}
