'use client';

import React from 'react';
import clsx from 'clsx';
import { useNavigation } from '@/lib/hooks/use-navigation';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useLeftSidebarContext } from '@context/left-sidebar-context';

function Navigation() {
  const { isExpanded } = useLeftSidebarContext();
  const { navigation } = useNavigation();
  const pathname = usePathname();
  return (
    <nav data-testid="main-navbar">
      <ul className="flex flex-col gap-2">
        {navigation.map((item) => (
          <li key={item.link} className="h-9">
            <Link
              href={item.link}
              className={clsx(
                'flex items-center gap-2 font-semibold text-gray-700 w-full h-full rounded-lg hover:bg-gray-50  px-2',
                {
                  'bg-gray-800 text-white hover:bg-gray-800':
                    pathname === item.link,
                },
              )}
            >
              {item.icon}
              <span className={clsx({ hidden: !isExpanded })}>
                {item.label}
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Navigation;
