'use client';

import React from 'react';
import clsx from 'clsx';
import Image from 'next/image';
import LogoArea from './logo-area';
import Navigation from './navigation';
import { useCatalogTranslation } from '@hooks/use-catalog-translations';
import { useStore } from '@hooks/use-store';
import LanguageDropdown from '@components/common/language-dropdown';
import LogoutButton from '@components/common/logout-button';
import { useLeftSidebarContext } from '@context/left-sidebar-context';
import LogoIcon from '../../common/icons/logo';

const LeftSidebar = () => {
  const { isExpanded } = useLeftSidebarContext();
  const { ct } = useCatalogTranslation('admin_common');
  const { data: store } = useStore();
  return (
    store && (
      <aside
        className={clsx(
          'flex flex-col justify-between min-h-screen max-h-screen py-5 px-3 border-r border-gray-100 transition-all duration-100 ease-linear',
          isExpanded ? 'w-[260px]' : 'w-14',
        )}
      >
        <div
          className={clsx('flex flex-col gap-5', {
            'divide-y divide-gray-100': isExpanded,
          })}
        >
          <LogoArea companyName={store?.name} />
          <div className="pt-5">
            <Navigation />
          </div>
        </div>
        <div className="flex flex-col items-start gap-3 py-3">
          <LanguageDropdown full={isExpanded} />
          <LogoutButton full={isExpanded} />
          {isExpanded ? (
            <div className="flex items-center text-gray-500 font-semibold gap-1">
              {ct('CommonSidebar.poweredBy', 'Propulsé par')}
              <Image
                src="/placeholder.svg"
                alt="catalog"
                className="translate-y-[2px]"
                width={54}
                height={14}
              />
            </div>
          ) : (
            <LogoIcon />
          )}
        </div>
      </aside>
    )
  );
};

export default LeftSidebar;
