'use client';

import React, { createContext, useContext, useEffect, useState } from 'react';

type LeftSidebarContextType = {
  isExpanded: boolean;
  toggleSidebar: () => void;
};

export const LeftSidebarContext = createContext<LeftSidebarContextType | null>(
  null,
);

export const LeftSidebarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storedState = localStorage.getItem('sidebar-expanded');
      if (storedState !== null) {
        setIsExpanded(JSON.parse(storedState));
      }
    }
  }, []);

  const toggleSidebar = () => {
    localStorage.setItem('sidebar-expanded', `${!isExpanded}`);
    setIsExpanded(!isExpanded);
  };
  return (
    <LeftSidebarContext.Provider value={{ isExpanded, toggleSidebar }}>
      {children}
    </LeftSidebarContext.Provider>
  );
};

export const useLeftSidebarContext = () => {
  const context = useContext(LeftSidebarContext);

  if (context === null) {
    throw new Error('useLeftSidebar must be used within LeftSidebarProvider');
  }

  return context;
};
