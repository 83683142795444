'use client';
import { WarningCircle } from 'iconoir-react';
import { useRouter } from 'next/navigation';
import { Trans, useTranslation } from 'react-i18next';
import { useAccount } from '@/lib/context/account-context';
import { useCatalogTranslation } from '@hooks/use-catalog-translations';
import { useTenant } from '../../../context/tenant-context';

const OnBehalfBanner = () => {
  const { firstName, lastName, impersonate } = useAccount();
  const { ct } = useCatalogTranslation('admin_common');
  const { t } = useTranslation('admin_common');
  const router = useRouter();
  const { backendUrl } = useTenant();

  const logoutAdmin = () => {
    router.push(`${backendUrl}/admin/auth/auth0`);
  };

  return (
    impersonate &&
    impersonate.email && (
      <div className="min-h-16 w-full flex items-center justify-start bg-orange-100 text-orange-600 px-4 py-1 gap-6">
        <WarningCircle className="h-6 w-6 text-orange-600" />
        <div className="text-[16px] text-orange-600 font-semibold">
          {ct('Common.adminAccess', 'Accès admin')}
        </div>
        <div className="text-[16px] text-black-100 bg-white rounded-xl inline-flex h-auto min-h-6 px-2 items-center justify-center ">
          {firstName} {lastName}
        </div>
        <div className="w-fit">
          <Trans
            t={t}
            i18nKey="Common.adminAccessDesc"
            values={{
              firstName: impersonate.firstName,
              lastName: impersonate.lastName,
              email: impersonate.email,
            }}
          >
            <p>
              Vous êtes actuellement connecté en tant que{' '}
              <strong>
                {impersonate.firstName} {impersonate.lastName}
              </strong>
              {impersonate.email}.
            </p>
          </Trans>
        </div>
        <div className="ml-auto">
          <button
            className="text-text-orange-600 hover:text-orange-700 text-base underline"
            onClick={async () => {
              await logoutAdmin();
            }}
          >
            {ct('CommonCta.logoutAdmin')}
          </button>
        </div>
      </div>
    )
  );
};

export default OnBehalfBanner;
