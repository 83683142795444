'use-client';

import React from 'react';
import clsx from 'clsx';
import { useAccount } from '@/lib/context/account-context';
import { useCatalogTranslation } from '@/lib/hooks/use-catalog-translations';
import LogoIcon from '@components/common/icons/logo';
import { FastArrowLeft } from 'iconoir-react';
import { useLeftSidebarContext } from '@context/left-sidebar-context';

type LogoAreaProps = {
  companyName: string;
};

const LogoArea = ({ companyName = '' }: LogoAreaProps) => {
  const { ct } = useCatalogTranslation('admin_common');
  const { isExpanded, toggleSidebar } = useLeftSidebarContext();
  const loggerdUser = useAccount();
  const { firstName } = loggerdUser;
  return (
    <div className="flex flex-col gap-5 px-2 min-h-[92px]">
      <div className="flex items-center justify-between">
        {isExpanded && <LogoIcon />}
        <button
          onClick={toggleSidebar}
          className={clsx(
            'transition-transform duration-300 ease-out cursor-pointer w-5 h-5',
            {
              'rotate-180': !isExpanded,
            },
          )}
        >
          <FastArrowLeft className="h-5 w-5 cursor-pointer" />
        </button>
      </div>
      {isExpanded && (
        <div className="text-gray-600 text-sm leading-5">
          {ct('CommonSidebar.hello', 'Bonjour')}{' '}
          <span className="text-primary-500">{firstName}</span>.{' '}
          {ct('CommonSidebar.welcome', 'Bienvenue sur votre espace ')}
          <span className="font-semibold">{companyName}.</span>
        </div>
      )}
    </div>
  );
};

export default LogoArea;
