import { Region, RegionFromApi } from '../types/region';
import { convertKeysToCamelCase } from './case-convertor';

export const buildRegion = (region: RegionFromApi): Region => {
  return {
    ...convertKeysToCamelCase<RegionFromApi, Region>(region, 3),
    paymentProviders: region.payment_providers
      ?.filter((pp) => pp.is_installed)
      .map((pp) => pp.id) as string[],
  };
};
