'use client';

import { datadogRum } from '@datadog/browser-rum';
import { ENVIRONMENT } from '../../config/config';
import pjson from '../../../package.json';

datadogRum.init({
  applicationId: '303e28d8-afe9-4157-9933-358d30cd41c1',
  clientToken: 'pub461c23ded289b1bdb0625f78c28d0d12',
  site: 'datadoghq.eu',
  service: 'seller-front',
  env: ENVIRONMENT,
  version: pjson.version,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  traceSampleRate: 100,
  defaultPrivacyLevel: 'allow',
  allowedTracingUrls: [
    /https:\/\/admin.*\.startcatalog\.com/,
    /https:\/\/admin.*\.catalogdemo\.fr/,
    /https:\/\/admin.*\.lacompagniedumas\.com/,
    /https:\/\/admin.*\.babymoov\.com/,
    /https:\/\/admin.*\.rivedroite-paris\.com/,
    /https:\/\/admin.*\.fimm\.fr/,
    /https:\/\/admin.*\.manufactureh\.com/,
    /https:\/\/admin.*\.cutbyfred\.com/,
    /https:\/\/admin.*\.nogent3etoiles\.com/,
    /https:\/\/admin.*\.gardette\.fr/,
  ],
});

export default function DatadogInit() {
  return null;
}
