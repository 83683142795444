'use client';

import React from 'react';
import { useCatalogTranslation } from '@hooks/use-catalog-translations';
import { auth0Logout } from '@/lib/config/config';
import { useRouter } from 'next/navigation';
import { LogOut } from 'iconoir-react';
import { useGlobalData } from '../../../data/data';

const LogoutButton = ({ full = true }: { full: boolean }) => {
  const { ct } = useCatalogTranslation('admin_common');
  const { logout } = useGlobalData();
  const router = useRouter();
  const handleLogout = async () => {
    await logout();
    const rootUrl = `${window.location.protocol}//${window.location.host}`;
    router.push(`${auth0Logout}?returnTo=${rootUrl}`);
  };
  return (
    <button
      data-testid="logout-button"
      className="text-error-600 text-sm font-body"
      onClick={handleLogout}
    >
      {full ? ct('Common.logout', 'Se déconnecter') : <LogOut />}
    </button>
  );
};

export default LogoutButton;
