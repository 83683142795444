'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AccountProvider } from './account-context';

const queryClient = new QueryClient();

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AccountProvider>{children}</AccountProvider>
    </QueryClientProvider>
  );
};

export default Providers;
