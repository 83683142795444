'use client';

import React, { createContext, useContext } from 'react';

interface ITenantContext {
  tenantId: string;
  backendUrl: string;
  type: 'regular' | 'legacy-prod' | 'legacy-nonprod';
}

const TenantContext = createContext<ITenantContext | null>(null);

export function TenantProvider({
  tenantInfo,
  children,
}: {
  tenantInfo: ITenantContext;
  children: React.ReactNode;
}) {
  return (
    <TenantContext.Provider value={tenantInfo}>
      {children}
    </TenantContext.Provider>
  );
}

export function useTenant() {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  return context;
}
