import { useCatalogTranslation } from './use-catalog-translations';
import { Emoji, List, Cart, Import, PageStar } from 'iconoir-react';
import { useSettings } from './use-settings';

export const useNavigation = () => {
  const { ct } = useCatalogTranslation('admin_common');
  const { displayActionsNavigationIcon } = useSettings();

  const navigation = [
    {
      label: ct('CommonNavItem.products', 'Produits'),
      icon: <List />,
      link: '/products',
    },
    {
      label: ct('CommonNavItem.customers', 'Clients'),
      icon: <Emoji />,
      link: '/customers',
    },
    {
      label: ct('CommonNavItem.orders', 'Commandes'),
      icon: <Cart />,
      link: '/orders',
    },
    {
      label: ct('CommonNavItem.smartOrders', 'Smart Orders'),
      icon: <PageStar />,
      link: '/smartorder',
    },
  ];
  if (displayActionsNavigationIcon) {
    navigation.push({
      label: ct('CommonNavItem.actions', 'Actions'),
      icon: <Import />,
      link: '/actions',
    });
  }

  return { navigation } as const;
};
