import { ListApiResponse } from '../data';
import { Setting, SettingApiSchema, SettingFromApi } from '../../types/setting';
import { QueryFunctionContext } from '@tanstack/react-query';
import { useCatalogRequest } from '../../hooks/use-catalog-request';
type SettingApiResponse = ListApiResponse & { settings: Setting[] };

export const useSettingsData = () => {
  const { catalogRequest } = useCatalogRequest();

  const getSettings = async (name?: string): Promise<SettingApiResponse> => {
    let path = '/settings?limit=1000';
    if (name) {
      path = `${path}&name=${name}`;
    }
    let result: SettingApiResponse | null = null;
    try {
      result = await catalogRequest('GET', path).then((res) => {
        const body = res.body;
        const settings: SettingFromApi[] =
          body.settings?.map((setting: SettingFromApi) =>
            SettingApiSchema.parse(setting),
          ) || [];
        return {
          ...body,
          settings,
        };
      });
    } catch (error) {
      console.error('Failed to fetch settings', error);
    }
    if (!result) {
      return {
        count: 0,
        offset: 0,
        limit: 0,
        settings: [],
      };
    }
    return result;
  };

  const fetchSettingsForReactQuery = async (_context: QueryFunctionContext) => {
    return getSettings();
  };

  return {
    getSettings,
    fetchSettingsForReactQuery,
  };
};
