import { z } from 'zod';

export const CurrencyApiSchema = z.object({
  code: z.string().optional(),
  symbol: z.string().optional(),
  name: z.string().optional(),
  symbol_native: z.string().optional(),
});
export const StoreApiSchema = z.object({
  id: z.string(),
  name: z.string(),
  default_currency_code: z.string().optional(),
  metadata: z.nullable(z.record(z.string())).optional(),
  currencies: z.array(CurrencyApiSchema).optional(),
  default_currency: z.nullable(CurrencyApiSchema).optional(),
  payment_providers: z
    .nullable(
      z.array(
        z.object({
          id: z.string(),
          is_installed: z.boolean(),
        }),
      ),
    )
    .optional(),
  fulfillment_providers: z
    .nullable(
      z.array(
        z.object({
          id: z.string(),
          is_installed: z.boolean(),
        }),
      ),
    )
    .optional(),
});

export type CurrencyFromApi = z.infer<typeof CurrencyApiSchema>;
export type StoreFromApi = z.infer<typeof StoreApiSchema>;

export type Currency = {
  code: string;
  symbol: string;
  name: string;
  symbolNative: string;
};

export type Provider = {
  id: string;
  isInstalled: boolean;
};

export type Store = {
  id: string;
  name: string;
  defaultCurrencyCode?: string;
  metadata?: Record<string, string>;
  currencies?: Currency[];
  defaultCurrency?: Currency;
  paymentProviders?: Provider[];
  fulfillmentProviders?: Provider[];
};
