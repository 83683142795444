import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  CatalogTranslationsNamespace,
  TranslationKeys,
} from '../types/i18n-types';

export type CTFunction = TFunction<CatalogTranslationsNamespace>;

// our hook can take one or many namespaces (T)
export const useCatalogTranslation = <
  T extends CatalogTranslationsNamespace | CatalogTranslationsNamespace[],
>(
  ns: T,
) => {
  const { t }: { t: CTFunction } = useTranslation(ns);

  // Prefix is a union type of the values of ns
  type Prefix = T extends CatalogTranslationsNamespace
    ? TranslationKeys[T]
    : T extends CatalogTranslationsNamespace[]
    ? TranslationKeys[T[number]]
    : never;

  // AuthorizedKey is composed of a defined key in TranslationKey + . + a string
  type AuthorizedKey = `${Prefix}.${string}`;

  // ct for catalogTranslation
  function ct(key: AuthorizedKey): ReturnType<CTFunction>;
  function ct(key: AuthorizedKey, defaultValue: string): ReturnType<CTFunction>;
  function ct(key: AuthorizedKey, options: any): ReturnType<CTFunction>;
  function ct(
    key: AuthorizedKey,
    defaultValue: string,
    options: any,
  ): ReturnType<CTFunction>;
  function ct(key: AuthorizedKey, defaultValue?: string, options?: any) {
    if (defaultValue && options) {
      return t(key, defaultValue, options);
    }
    if (!defaultValue && options) {
      return t(key, options);
    }
    if (defaultValue && !options) {
      return t(key, defaultValue);
    }
    return t(key);
  }

  return { ct };
};
